import { css, FlattenSimpleInterpolation } from "styled-components";
import { BREAKPOINT } from "./theme";

export type ResponsiveMixinProps<T> = {
  mixin: (value: T) => FlattenSimpleInterpolation;
  mobile?: T;
  tablet?: T;
  tabletLandscape?: T;
  desktop?: T;
  largeDesktop?: T;
  retailerTerminal?: T;
};

export const responsiveMixin = <T,>({
  mixin,
  mobile,
  tablet,
  tabletLandscape,
  desktop,
  largeDesktop,
  retailerTerminal,
}: ResponsiveMixinProps<T>) => css`
  ${typeof mobile !== "undefined" && mixin(mobile)}

  ${typeof tablet !== "undefined" &&
  css`
    @media (min-width: ${BREAKPOINT.TABLET}px) {
      ${mixin(tablet)}
    }
  `}

  ${typeof tabletLandscape !== "undefined" &&
  css`
    @media (min-width: ${BREAKPOINT.TABLET_LANDSCAPE}px) {
      ${mixin(tabletLandscape)}
    }
  `}

  ${typeof desktop !== "undefined" &&
  css`
    @media (min-width: ${BREAKPOINT.DESKTOP}px) {
      ${mixin(desktop)}
    }
  `}

  ${typeof largeDesktop !== "undefined" &&
  css`
    @media (min-width: ${BREAKPOINT.LARGE_DESKTOP}px) {
      ${mixin(largeDesktop)}
    }
  `}

  ${typeof retailerTerminal !== "undefined" && mixin(retailerTerminal)}
`;

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import React, { useMemo } from "react";

const stripeOptions = {
  fonts: [
    {
      cssSrc:
        "https://fonts.googleapis.com/css?family=Lato:400,700&display=swap",
    },
  ],
};

type StripeProviderProps = {
  stripePublishableKey: string;
  children: React.ReactNode;
};
const StripeProvider = ({
  stripePublishableKey,
  children,
}: StripeProviderProps) => {
  const stripe = useMemo(
    () => loadStripe(stripePublishableKey, { apiVersion: "2020-08-27" }),
    [stripePublishableKey],
  );

  return (
    <Elements stripe={stripe} options={stripeOptions}>
      {children}
    </Elements>
  );
};

export default StripeProvider;

import { createHttpLink, InMemoryCache, ApolloClient } from "@apollo/client";
import fragmentTypes from "./fragment-types";

const link = createHttpLink({ uri: "/graphql" });

const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes: fragmentTypes.possibleTypes,
    typePolicies: {},
  }),
  link,
  name: `web - ${process.env.ENVIRONMENT}`,
  version: process.env.RELEASE_ID,
  assumeImmutableResults: true,
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
  },
});

export default apolloClient;

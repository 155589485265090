import lottie from "lottie-web";
import React, { memo, useState, useEffect, useCallback } from "react";

const _Lottie: React.FC<{
  loop?: boolean;
  animationData: unknown;
  className?: string;
}> = ({ loop = false, className, animationData }) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!container) {
      return;
    }
    const animationItem = lottie.loadAnimation({
      container,
      renderer: "svg",
      loop,
      animationData,
      autoplay: true,
    });

    return () => {
      animationItem.destroy();
    };
  }, [animationData, container, loop]);

  const handleContainerRefUpdate = useCallback(
    (container: HTMLDivElement | null) => {
      setContainer(container);
    },
    [],
  );

  return <div className={className} ref={handleContainerRefUpdate} />;
};

const Lottie = memo(_Lottie);
export default Lottie;

/* eslint-disable */

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AcceptExpertApplicationMutationResult: [
      "AcceptExpertApplicationMutationFailure",
      "AcceptExpertApplicationMutationSuccess",
    ],
    AcceptJobOfferMutationResult: [
      "AcceptJobOfferMutationFailure",
      "AcceptJobOfferMutationSuccess",
    ],
    AddSelfStripeConnectExternalAccountMutationResult: [
      "AddSelfStripeConnectExternalAccountMutationFailure",
      "AddSelfStripeConnectExternalAccountMutationSuccess",
    ],
    AsAdmin_CancelJobOfferMutationResult: [
      "AsAdmin_CancelJobOfferMutationFailure",
      "AsAdmin_CancelJobOfferMutationSuccess",
    ],
    AsAdmin_CancelMissionMutationResult: [
      "AsAdmin_CancelMissionMutationFailure",
      "AsAdmin_CancelMissionMutationSuccess",
    ],
    AsAdmin_CreateRetailerAPIKeyMutationResult: [
      "AsAdmin_CreateRetailerAPIKeyMutationFailure",
      "AsAdmin_CreateRetailerAPIKeyMutationSuccess",
    ],
    AsAdmin_DuplicateStoreMutationResult: [
      "AsAdmin_DuplicateStoreMutationFailure",
      "AsAdmin_DuplicateStoreMutationSuccess",
    ],
    AsAdmin_GenerateLatestStoreClerksSalesReportMutationResult: [
      "AsAdmin_GenerateLatestStoreClerksSalesReportMutationFailure",
      "AsAdmin_GenerateLatestStoreClerksSalesReportMutationSuccess",
    ],
    AsAdmin_GetStoreClerksSalesReportQueryResult: [
      "AsAdmin_GetStoreClerksSalesReportQueryFailure",
      "AsAdmin_GetStoreClerksSalesReportQuerySuccess",
    ],
    AsAdmin_RefreshAllMissionsMutationResult: [
      "AsAdmin_RefreshAllMissionsMutationFailure",
      "AsAdmin_RefreshAllMissionsMutationSuccess",
    ],
    AsAdmin_RefreshExpertStripeKYCStatusMutationResult: [
      "AsAdmin_RefreshExpertStripeKYCStatusMutationFailure",
      "AsAdmin_RefreshExpertStripeKYCStatusMutationSuccess",
    ],
    AsAdmin_UpdateExpertBusinessTypeMutationResult: [
      "AsAdmin_UpdateExpertBusinessTypeMutationFailure",
      "AsAdmin_UpdateExpertBusinessTypeMutationSuccess",
    ],
    AsAdmin_UpdateExpertLiteMutationResult: [
      "AsAdmin_UpdateExpertLiteMutationFailure",
      "AsAdmin_UpdateExpertLiteMutationSuccess",
    ],
    AsAdmin_UpdateExpertOnboardingStatusMutationResult: [
      "AsAdmin_UpdateExpertOnboardingStatusMutationFailure",
      "AsAdmin_UpdateExpertOnboardingStatusMutationSuccess",
    ],
    AsCustomer_AcceptQuoteWithInPersonPaymentMutationResult: [
      "AsCustomer_AcceptQuoteWithInPersonPaymentMutationFailure",
      "AsCustomer_AcceptQuoteWithInPersonPaymentMutationSuccess",
    ],
    AsCustomer_AcceptQuoteWithStripePaymentIntentMutationResult: [
      "AsCustomer_AcceptQuoteWithStripePaymentIntentMutationFailure",
      "AsCustomer_AcceptQuoteWithStripePaymentIntentMutationSuccess",
    ],
    AsCustomer_AcceptQuoteWithStripePaymentMethodMutationResult: [
      "AsCustomer_AcceptQuoteWithStripePaymentMethodMutationFailure",
      "AsCustomer_AcceptQuoteWithStripePaymentMethodMutationPaymentError",
      "AsCustomer_AcceptQuoteWithStripePaymentMethodMutationRequiresClientAction",
      "AsCustomer_AcceptQuoteWithStripePaymentMethodMutationSuccess",
    ],
    AsCustomer_ConfirmDraftQuoteRequestMutationResult: [
      "AsCustomer_ConfirmDraftQuoteRequestMutationFailure",
      "AsCustomer_ConfirmDraftQuoteRequestMutationSuccess",
    ],
    AsCustomer_RejectQuoteRequestCurrentAcceptedQuoteOfferMutationResult: [
      "AsCustomer_RejectQuoteRequestCurrentAcceptedQuoteOfferMutationFailure",
      "AsCustomer_RejectQuoteRequestCurrentAcceptedQuoteOfferMutationSuccess",
    ],
    AsCustomer_RejectQuoteRequestCurrentAnsweredQuoteOfferMutationResult: [
      "AsCustomer_RejectQuoteRequestCurrentAnsweredQuoteOfferMutationFailure",
      "AsCustomer_RejectQuoteRequestCurrentAnsweredQuoteOfferMutationSuccess",
    ],
    AsCustomer_SubmitMissionServiceIncentiveFactorResult: [
      "AsCustomer_SubmitMissionServiceIncentiveFactorFailure",
      "AsCustomer_SubmitMissionServiceIncentiveFactorSuccess",
    ],
    AsCustomer_SubmitQuoteRequestMutationResult: [
      "AsCustomer_SubmitQuoteRequestMutationFailure",
      "AsCustomer_SubmitQuoteRequestMutationSuccess",
    ],
    AsExpert_AcceptQuoteOfferMutationResult: [
      "AsExpert_AcceptQuoteOfferMutationFailure",
      "AsExpert_AcceptQuoteOfferMutationSuccess",
    ],
    AsExpert_AnswerToAcceptedQuoteOfferMutationResult: [
      "AsExpert_AnswerToAcceptedQuoteOfferMutationFailure",
      "AsExpert_AnswerToAcceptedQuoteOfferMutationSuccess",
    ],
    AsExpert_CreateStripeSEPASetupIntentMutationResult: [
      "AsExpert_CreateStripeSEPASetupIntentMutationFailure",
      "AsExpert_CreateStripeSEPASetupIntentMutationSuccess",
    ],
    AsExpert_DeclineQuoteOfferMutationResult: [
      "AsExpert_DeclineQuoteOfferMutationFailure",
      "AsExpert_DeclineQuoteOfferMutationSuccess",
    ],
    AsExpert_EnableInPersonPaymentMutationResultSource: [
      "AsExpert_EnableInPersonPaymentMutationFailure",
      "AsExpert_EnableInPersonPaymentMutationSuccess",
    ],
    AsExpert_SubmittedToNeedInformationQuoteOfferMutationResult: [
      "AsExpert_SubmittedToNeedInformationQuoteOfferMutationFailure",
      "AsExpert_SubmittedToNeedInformationQuoteOfferMutationSuccess",
    ],
    AsExpert_UpdateExpectStartMutationResult: [
      "AsExpert_UpdateExpectStartMutationFailure",
      "AsExpert_UpdateExpectStartMutationSuccess",
    ],
    AsExpert_UpdateProfileMutationResult: [
      "AsExpert_UpdateProfileMutationFailure",
      "AsExpert_UpdateProfileMutationSuccess",
    ],
    AsStoreClerk_SendDraftMissionOnlinePaymentLinkMutationResult: [
      "AsStoreClerk_SendDraftMissionOnlinePaymentLinkMutationFailure",
      "AsStoreClerk_SendDraftMissionOnlinePaymentLinkMutationSuccess",
    ],
    AsStoreClerk_SignUpMutationResult: [
      "AsStoreClerk_SignUpMutationFailure",
      "AsStoreClerk_SignUpMutationSuccess",
    ],
    AsStoreClerk_SubmitDraftQuoteRequestMutationResult: [
      "AsStoreClerk_SubmitDraftQuoteRequestMutationFailure",
      "AsStoreClerk_SubmitDraftQuoteRequestMutationSuccess",
    ],
    AsStoreClerk_SubmitMissionMutationResult: [
      "AsStoreClerk_SubmitMissionMutationFailure",
      "AsStoreClerk_SubmitMissionMutationSuccess",
    ],
    AsUniproUser_SubscribeUrssafMutationResult: [
      "AsUniproUser_SubscribeUrssafMutationFailure",
      "AsUniproUser_SubscribeUrssafMutationSuccess",
    ],
    AvailableThemesInLocationQueryResult: [
      "AvailableThemesInLocationQueryFailure",
      "AvailableThemesInLocationQuerySuccess",
    ],
    CalculateServicesFeesAndTotalPriceQueryResult: [
      "CalculateServicesFeesAndTotalPriceQueryFailure",
      "CalculateServicesFeesAndTotalPriceQuerySuccess",
    ],
    CancelMissionMutationResult: [
      "CancelMissionMutationFailure",
      "CancelMissionMutationSuccess",
    ],
    CategoryAvailableOffersFieldResult: [
      "CategoryAvailableOffersFieldFailure",
      "CategoryAvailableOffersFieldSuccess",
    ],
    ConfirmStripePaymentIntentAndSubmitMissionMutationResult: [
      "ConfirmStripePaymentIntentAndSubmitMissionMutationFailure",
      "ConfirmStripePaymentIntentAndSubmitMissionMutationPaymentError",
      "ConfirmStripePaymentIntentAndSubmitMissionMutationSuccess",
    ],
    ConfirmStripePaymentMethodAndSubmitMissionMutationResult: [
      "ConfirmStripePaymentMethodAndSubmitMissionMutationFailure",
      "ConfirmStripePaymentMethodAndSubmitMissionMutationPaymentError",
      "ConfirmStripePaymentMethodAndSubmitMissionMutationRequiresClientAction",
      "ConfirmStripePaymentMethodAndSubmitMissionMutationSuccess",
    ],
    CreateExpertApplicationMutationResult: [
      "ApplyAsExpertMutationFailure",
      "ApplyAsExpertMutationSuccess",
    ],
    DeclineJobOfferMutationResult: [
      "DeclineJobOfferMutationFailure",
      "DeclineJobOfferMutationSuccess",
    ],
    EditOnboardingRequestMutationResult: [
      "EditOnboardingRequestMutationFailure",
      "EditOnboardingRequestMutationSuccess",
    ],
    GenerateSelfStripeConnectAccountOnboardingLinkMutationResult: [
      "GenerateSelfStripeConnectAccountOnboardingLinkMutationFailure",
      "GenerateSelfStripeConnectAccountOnboardingLinkMutationSuccess",
    ],
    IsOfferAvailableAtLocationQueryResult: [
      "IsOfferAvailableAtLocationQueryFailure",
      "IsOfferAvailableAtLocationQuerySuccess",
    ],
    LoginMutationResult: ["LoginMutationFailure", "LoginMutationSuccess"],
    MarkAcceptedMissionAsDoneMutationResult: [
      "MarkAcceptedMissionAsDoneMutationFailure",
      "MarkAcceptedMissionAsDoneSuccess",
    ],
    MarkJobOfferFinishSurveyAnsweredMutationResult: [
      "MarkJobOfferFinishSurveyAnsweredMutationFailure",
      "MarkJobOfferFinishSurveyAnsweredMutationSuccess",
    ],
    MeQueryResult: ["MeQueryFailure", "MeQuerySuccess"],
    Mission: [
      "AcceptedMission",
      "CancelledMission",
      "DoneMission",
      "MissionDraft",
      "PendingValidationUrssafMission",
      "SubmittedMission",
    ],
    MissionIsInPersonPaymentAvailableFieldResult: [
      "MissionIsInPersonPaymentAvailableFieldFailure",
      "MissionIsInPersonPaymentAvailableFieldSuccess",
    ],
    MissionQuestionAnswer: [
      "NumberMissionQuestionAnswer",
      "StringMissionQuestionAnswer",
    ],
    PostMissionDraftMutationResult: [
      "PostMissionDraftMutationFailure",
      "PostMissionDraftMutationSuccess",
    ],
    PostMissionMutationResult: [
      "PostMissionMutationFailure",
      "PostMissionMutationSuccess",
    ],
    Private_Expert: [
      "Private_CompanyExpert",
      "Private_PrivateIndividualExpert",
      "Private_SelfEmployedExpert",
    ],
    Public_Expert: [
      "Public_CompanyExpert",
      "Public_PrivateIndividualExpert",
      "Public_SelfEmployedExpert",
    ],
    Question: [
      "MultipleChoiceQuestion",
      "MultipleChoicesQuestion",
      "NumberQuestion",
      "TextQuestion",
    ],
    Quote: ["AcceptedQuote", "SubmittedQuote"],
    QuoteOffer: [
      "AcceptedQuoteOffer",
      "AnsweredQuoteOffer",
      "CanceledQuoteOffer",
      "DeclinedQuoteOffer",
      "ExpiredQuoteOffer",
      "NeedInformationQuoteOffer",
      "RejectedQuoteOffer",
      "SubmittedQuoteOffer",
    ],
    QuoteRequest: [
      "CanceledQuoteRequest",
      "ConfirmedQuoteRequest",
      "DraftQuoteRequest",
    ],
    QuoteRequestQuestionAnswer: [
      "NumberQuoteRequestQuestionAnswer",
      "StringQuoteRequestQuestionAnswer",
    ],
    RateServiceMutationResult: [
      "RateServiceMutationFailure",
      "RateServiceMutationSuccess",
    ],
    RejectExpertApplicationMutationResult: [
      "RejectExpertApplicationMutationFailure",
      "RejectExpertApplicationMutationSuccess",
    ],
    RejectExpertMutationResult: [
      "RejectExpertMutationFailure",
      "RejectExpertMutationSuccess",
    ],
    RequestOnboardingMutationResult: [
      "RequestOnboardingMutationFailure",
      "RequestOnboardingMutationSuccess",
    ],
    RequestPhoneNumberVerificationCodeMutationResult: [
      "RequestPhoneNumberVerificationCodeMutationFailure",
      "RequestPhoneNumberVerificationCodeMutationSuccess",
    ],
    SetMissionAddressMutationResult: [
      "SetMissionAddressMutationFailure",
      "SetMissionAddressMutationSuccess",
    ],
    SetMissionDateMutationResult: [
      "SetMissionDateMutationFailure",
      "SetMissionDateMutationSuccess",
    ],
    SetMissionHostMutationResult: [
      "SetMissionHostMutationFailure",
      "SetMissionHostMutationSuccess",
    ],
    SetMissionHostPhoneNumberMutationResult: [
      "SetMissionHostPhoneNumberMutationFailure",
      "SetMissionHostPhoneNumberMutationSuccess",
    ],
    SetMissionPostalCodeAsAddressMutationResult: [
      "SetMissionPostalCodeAsAddressMutationFailure",
      "SetMissionPostalCodeAsAddressMutationSuccess",
    ],
    SubmitMissionWithInPersonPaymentIntentMutationResult: [
      "SubmitMissionWithInPersonPaymentIntentMutationFailure",
      "SubmitMissionWithInPersonPaymentIntentMutationSuccess",
    ],
    SubmitNumberMissionQuestionAnswerMutationResult: [
      "SubmitNumberMissionQuestionAnswerMutationFailure",
      "SubmitNumberMissionQuestionAnswerMutationSuccess",
    ],
    SubmitQuoteRequestWithinDiagnosticMissionResult: [
      "SubmitQuoteRequestWithinDiagnosticMissionFailure",
      "SubmitQuoteRequestWithinDiagnosticMissionSuccess",
    ],
    SubmitSapMissionMutationResult: [
      "SubmitSapMissionMutationFailure",
      "SubmitSapMissionMutationSuccess",
    ],
    SubmitStringMissionQuestionAnswerMutationResult: [
      "SubmitStringMissionQuestionAnswerMutationFailure",
      "SubmitStringMissionQuestionAnswerMutationSuccess",
    ],
    ThemeAvailableCategoriesFieldResult: [
      "ThemeAvailableCategoriesFieldFailure",
      "ThemeAvailableCategoriesFieldSuccess",
    ],
    UpdateMissionExtraDetailsMutationResult: [
      "UpdateMissionExtraDetailsMutationFailure",
      "UpdateMissionExtraDetailsMutationSuccess",
    ],
    ValidateExpertApplicationMutationResult: [
      "ValidateExpertApplicationMutationFailure",
      "ValidateExpertApplicationMutationSuccess",
    ],
    ValidateExpressionQueryResult: [
      "ValidateExpressionQueryValidQueryResult",
      "ValidateExpressionSyntaxErrorQueryResult",
      "ValidateExpressionUnknownExpressionReferenceQueryResult",
      "ValidateExpressionUnknownQuestionReferenceQueryResult",
    ],
    VerifyPhoneNumberMutationResult: [
      "VerifyPhoneNumberMutationFailure",
      "VerifyPhoneNumberMutationSuccess",
    ],
  },
};
export default result;

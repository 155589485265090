import styled, { css } from "styled-components";

import React from "react";
import { COLOR, SIZE_UNIT } from "./theme";
import { responsiveMixin } from "./style-mixins";
import Spinner from "./spinner";

const commonBoxShadow = css`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07), 0px 2px 2px rgba(0, 0, 0, 0.07);
`;

const primaryVariant = css`
  ${commonBoxShadow}
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${COLOR.NEUTRAL_WHITE};
`;

const largePrimaryVariant = css`
  ${commonBoxShadow}
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${COLOR.NEUTRAL_WHITE};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  ${responsiveMixin({
    mixin: (minHeight: number) => css`
      min-height: ${minHeight}px;
    `,
    mobile: 56,
    tablet: 48,
  })}
`;

const primaryAltVariant = css`
  ${commonBoxShadow}
  background-color: ${COLOR.ACCENT_MENTHE};
  color: ${COLOR.NEUTRAL_WHITE};
`;

const neutralVariant = css`
  background: ${COLOR.NEUTRAL_GREY_5};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
  color: ${COLOR.NEUTRAL_GREY_1};
`;

const largeSecondaryColorVariant = css`
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${COLOR.NEUTRAL_WHITE};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  ${responsiveMixin({
    mixin: (minHeight: number) => css`
      min-height: ${minHeight}px;
    `,
    mobile: 56,
    tablet: 48,
  })}
`;

const secondaryColorVariant = css`
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${COLOR.NEUTRAL_WHITE};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
`;

const secondaryVariant = css`
  background: ${COLOR.NEUTRAL_WHITE};
  color: ${COLOR.NEUTRAL_GREY_2};
`;

const disabledVariant = css`
  background: ${COLOR.NEUTRAL_GREY_4};
  color: ${COLOR.NEUTRAL_GREY_3};
`;

const largeDisabledVariant = css`
  background: ${COLOR.NEUTRAL_GREY_4};
  color: ${COLOR.NEUTRAL_GREY_3};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  ${responsiveMixin({
    mixin: (minHeight: number) => css`
      min-height: ${minHeight}px;
    `,
    mobile: 56,
    tablet: 48,
  })}
`;

const dangerVariant = css`
  ${commonBoxShadow}
  background: ${COLOR.TERTIARY_CANCELED};
  color: ${COLOR.NEUTRAL_WHITE};
`;

const nakedVariant = css`
  background: transparent;
  color: ${COLOR.NEUTRAL_GREY_2};
  padding: 0 3px;
`;

const nakedColorVariant = css`
  background: transparent;
  color: ${({ theme }) => theme.colors.primary};
  padding: 0 3px;
`;

const blockStyle = css`
  width: 100%;
`;

const loadingVariant = css`
  ${commonBoxShadow}
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${COLOR.NEUTRAL_WHITE};
`;

type StyledButtonProps = {
  variant?:
    | "primary"
    | "large-primary"
    | "primary-alt"
    | "neutral"
    | "secondary"
    | "secondary-color"
    | "large-secondary-color"
    | "disabled"
    | "large-disabled"
    | "danger"
    | "naked"
    | "naked-color";
  block?: boolean;

  height?: number;
  heightTablet?: number;
  heightTabletLandscape?: number;
  heightDesktop?: number;
  heightLargeDesktop?: number;

  width?: string;
  widthTablet?: string;
  widthTabletLandscape?: string;
  widthDesktop?: string;
  widthLargeDesktop?: string;

  loading?: boolean;
};
const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 15px;
  padding: 0 ${SIZE_UNIT * 3}px;
  font-size: 16px;
  line-height: 21px;
  white-space: nowrap;
  font-weight: bold;
  outline: none;
  overflow: hidden;
  &:focus {
    outline: none;
  }

  ${({ variant = "primary", loading = 0 }) => {
    if (loading) {
      return loadingVariant;
    }

    switch (variant) {
      case "primary":
        return primaryVariant;
      case "large-primary":
        return largePrimaryVariant;
      case "neutral":
        return neutralVariant;
      case "large-secondary-color":
        return largeSecondaryColorVariant;
      case "secondary-color":
        return secondaryColorVariant;
      case "secondary":
        return secondaryVariant;
      case "disabled":
        return disabledVariant;
      case "danger":
        return dangerVariant;
      case "naked":
        return nakedVariant;
      case "naked-color":
        return nakedColorVariant;
      case "primary-alt":
        return primaryAltVariant;
      case "large-disabled":
        return largeDisabledVariant;
    }
  }}
  ${({ block = false }) => block && blockStyle}
  ${({
    width,
    widthTablet,
    widthTabletLandscape,
    widthDesktop,
    widthLargeDesktop,
  }) =>
    responsiveMixin({
      mixin: (width: string) => css`
        width: ${width};
      `,
      mobile: width,
      tablet: widthTablet,
      desktop: widthDesktop,
      tabletLandscape: widthTabletLandscape,
      largeDesktop: widthLargeDesktop,
    })}
  ${({
    height = SIZE_UNIT * 6,
    heightTablet,
    heightTabletLandscape,
    heightDesktop,
    heightLargeDesktop,
  }) =>
    responsiveMixin({
      mixin: (height) => css`
        height: ${height}px;
      `,
      mobile: height,
      tablet: heightTablet,
      desktop: heightTabletLandscape,
      tabletLandscape: heightDesktop,
      largeDesktop: heightLargeDesktop,
    })}
`;

const Button: React.FC<
  React.ComponentProps<typeof StyledButton> & StyledButtonProps
> = ({ children, loading, ...props }) => {
  return (
    <StyledButton {...props} loading={loading ? 1 : 0}>
      {loading ? <Spinner /> : children}
    </StyledButton>
  );
};

export default Button;

import { ZendeskAPI } from "react-zendesk";

export const showZendesk = () => {
  ZendeskAPI("messenger", "show");
};

export const hideZendesk = () => {
  ZendeskAPI("messenger", "hide");
};

export const openZendesk = () => {
  ZendeskAPI("messenger", "show");
  ZendeskAPI("messenger", "open");
};

import { gql, useQuery } from "@apollo/client";
import React, { createContext, useContext } from "react";
import {
  FeatureFlagProviderQuery,
  FeatureFlagProviderQueryVariables,
  FeatureFlagProviderFeatureFlagFragment,
} from "../services/graphql/operations-types";

import Loading from "../deprecated/organisms/loading";
import { useRetailer } from "./use-retailer";

const FEATURE_FLAG_PROVIDER_QUERY = gql`
  fragment FeatureFlagProviderFeatureFlag on FeatureFlag {
    name
    isEnabled
    retailerIdExclusions
  }

  query FeatureFlagProvider {
    featureFlags {
      ...FeatureFlagProviderFeatureFlag
    }
  }
`;

const FeatureFlagsContext = createContext<
  readonly FeatureFlagProviderFeatureFlagFragment[]
>([]);

type FeatureFlagsProviderProps = { children: React.ReactNode };
export const FeatureFlagsProvider = ({
  children,
}: FeatureFlagsProviderProps) => {
  const { loading, data, error } = useQuery<
    FeatureFlagProviderQuery,
    FeatureFlagProviderQueryVariables
  >(FEATURE_FLAG_PROVIDER_QUERY);

  if (error) {
    throw error;
  }

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <FeatureFlagsContext.Provider value={data.featureFlags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

const useIsFeatureFlagEnabled = (featureFlagName: string) => {
  const featureFlags = useContext(FeatureFlagsContext);
  const flag = featureFlags.filter(
    (featureFlag) => featureFlag.name === featureFlagName,
  )[0];

  try {
    const retailer = useRetailer();

    if (!flag) {
      return false;
    }
    if (
      flag.retailerIdExclusions &&
      flag.retailerIdExclusions.includes(retailer.id)
    ) {
      return !flag.isEnabled;
    } else {
      return flag.isEnabled;
    }
  } catch (e) {
    if (!flag) {
      return false;
    }

    return flag.isEnabled;
  }
};

export default useIsFeatureFlagEnabled;

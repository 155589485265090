import styled, { css } from "styled-components";
import { responsiveMixin } from "./style-mixins";

const headingVariant1 = css`
  font-size: 48px;
  line-height: 52px;
`;

const headingVariant2 = css`
  font-size: 34px;
  line-height: 38px;
`;

const headingVariant30px = css`
  font-size: 30px;
  line-height: 46px;
`;

const headingVariant3 = css`
  font-size: 32px;
  line-height: 36px;
`;

const headingVariant4 = css`
  font-size: 24px;
  line-height: 28px;
`;

const headingVariant5 = css`
  font-size: 18px;
  line-height: 22px;
`;

const headingBlack34 = css`
  font-weight: 900;
  font-size: 34px;
  line-height: 38px;
`;

const headingBlack40 = css`
  font-weight: 900;
  font-size: 40px;
  line-height: 52px;
`;

const colorMixin = (color: string) => css`
  color: ${color};
`;

const textTransformMixin = (textTransform: string) => css`
  text-transform: ${textTransform};
`;

const fontStyleMixin = (fontStyle: string) => css`
  font-style: ${fontStyle};
`;

type HeadingVariant =
  | 1
  | 2
  | 3
  | 4
  | 5
  | "Heading/Bold 18px"
  | "Heading/Bold 24px"
  | "Heading/Bold 30px"
  | "Heading/Bold 32px"
  | "Heading/Bold 48px"
  | "Heading/Black 34px"
  | "Heading/Black 40px";
type HeadingProps = {
  as?: "h1" | "h2" | "h3" | "h4" | "h5";
  variant: HeadingVariant;
  variantDesktop?: HeadingVariant;
  variantLargeDesktop?: HeadingVariant;
  variantTablet?: HeadingVariant;
  variantTabletLandscape?: HeadingVariant;
  variantRetailerTerminal?: HeadingVariant;
  color?: string;
};
export const Heading = styled.h1<HeadingProps>`
  font-weight: bold;
  ${({ color }) => typeof color === "string" && colorMixin(color)}

  ${({
    variant,
    variantDesktop,
    variantTablet,
    variantLargeDesktop,
    variantTabletLandscape,
    variantRetailerTerminal,
  }) =>
    responsiveMixin({
      mixin: (variant) => {
        switch (variant) {
          case 1:
          case "Heading/Bold 48px":
            return headingVariant1;
          case "Heading/Black 34px":
            return headingBlack34;
          case "Heading/Black 40px":
            return headingBlack40;
          case 2:
            return headingVariant2;
          case "Heading/Bold 30px":
            return headingVariant30px;
          case 3:
          case "Heading/Bold 32px":
            return headingVariant3;
          case 4:
          case "Heading/Bold 24px":
            return headingVariant4;
          case 5:
          case "Heading/Bold 18px":
            return headingVariant5;
        }
      },
      desktop: variantRetailerTerminal
        ? variantRetailerTerminal
        : variantDesktop,
      largeDesktop: variantRetailerTerminal
        ? variantRetailerTerminal
        : variantLargeDesktop,
      mobile: variantRetailerTerminal ? variantRetailerTerminal : variant,
      tablet: variantRetailerTerminal ? variantRetailerTerminal : variantTablet,
      tabletLandscape: variantRetailerTerminal
        ? variantRetailerTerminal
        : variantTabletLandscape,
    })}
`;

const textVariantButtonBold16px = css`
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
`;

const textVariantButtonBold18px = css`
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
`;

const textVariantUppercaseBold12px = css`
  font-weight: bold;
  font-size: 12px;
  line-height: 13px;
  text-transform: uppercase;
`;

const textVariantUppercaseBold14px = css`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
`;

const textVariantBodyRegular14px = css`
  font-size: 14px;
  line-height: 18px;
`;

const textVariantBodyRegular16px = css`
  font-size: 16px;
  line-height: 21px;
`;

const textVariantBodyRegular20px = css`
  font-size: 20px;
  line-height: 24px;
`;

const textVariantSmallTextRegular10px = css`
  font-size: 10px;
  line-height: 13px;
`;

const textVariantSmallTextRegular12px = css`
  font-size: 12px;
  line-height: 15px;
`;

const textVariantBodyBold14px = css`
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
`;

const textVariantBodyBold16px = css`
  font-size: 16px;
  line-height: 21px;
  font-weight: bold;
`;

const textVariantBodyBold18px = css`
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
`;

const textVariantBodyBold20px = css`
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
`;

const textVariantBodyBlack24px = css`
  font-size: 24px;
  line-height: 36px;
  font-weight: 900;
`;

const textVariantBodyBlack34px = css`
  font-size: 34px;
  line-height: 38px;
  font-weight: 900;
`;

const textVariantBodyBold32px = css`
  font-size: 32px;
  line-height: 36px;
  font-weight: bold;
`;
const textVariantBodyBold40px = css`
  font-size: 40px;
  line-height: 52px;
  font-weight: bold;
`;

const textVariantCommentItalic14px = css`
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
`;

type TextVariant =
  | "Button/Bold 16px"
  | "Button/Bold 18px"
  | "UPPERCASE/Bold 12px"
  | "UPPERCASE/Bold 14px"
  | "Body/Regular 14px"
  | "Body/Regular 16px"
  | "Body/Regular 20px"
  | "Small Text/Regular 10px"
  | "Small Text/Regular 12px"
  | "Body/Bold 14px"
  | "Body/Bold 16px"
  | "Body/Bold 18px"
  | "Body/Bold 20px"
  | "Body/Black 24px"
  | "Body/Black 34px"
  | "Body/Bold 32px"
  | "Body/Bold 40px"
  | "Comment/Italic 14px";
type TextProps = {
  variant: TextVariant;
  variantDesktop?: TextVariant;
  variantTablet?: TextVariant;
  variantLargeDesktop?: TextVariant;
  variantTabletLandscape?: TextVariant;

  color?: string;
  textTransform?: string;
  fontStyle?: string;
};
export const Text = styled.span<TextProps>`
  display: inline-block;
  ${({ fontStyle }) =>
    typeof fontStyle === "string" && fontStyleMixin(fontStyle)}
  ${({ color }) => typeof color === "string" && colorMixin(color)}
  ${({ textTransform }) =>
    typeof textTransform === "string" && textTransformMixin(textTransform)}

  ${({
    variant,
    variantDesktop,
    variantTablet,
    variantLargeDesktop,
    variantTabletLandscape,
  }) =>
    responsiveMixin({
      mixin: (variant) => {
        switch (variant) {
          case "Button/Bold 16px":
            return textVariantButtonBold16px;
          case "Button/Bold 18px":
            return textVariantButtonBold18px;
          case "UPPERCASE/Bold 12px":
            return textVariantUppercaseBold12px;
          case "UPPERCASE/Bold 14px":
            return textVariantUppercaseBold14px;
          case "Body/Regular 14px":
            return textVariantBodyRegular14px;
          case "Body/Regular 16px":
            return textVariantBodyRegular16px;
          case "Body/Regular 20px":
            return textVariantBodyRegular20px;
          case "Small Text/Regular 10px":
            return textVariantSmallTextRegular10px;
          case "Small Text/Regular 12px":
            return textVariantSmallTextRegular12px;
          case "Body/Bold 14px":
            return textVariantBodyBold14px;
          case "Body/Bold 16px":
            return textVariantBodyBold16px;
          case "Body/Bold 18px":
            return textVariantBodyBold18px;
          case "Body/Bold 20px":
            return textVariantBodyBold20px;
          case "Body/Black 24px":
            return textVariantBodyBlack24px;
          case "Body/Black 34px":
            return textVariantBodyBlack34px;
          case "Body/Bold 32px":
            return textVariantBodyBold32px;
          case "Body/Bold 40px":
            return textVariantBodyBold40px;
          case "Comment/Italic 14px":
            return textVariantCommentItalic14px;
        }
      },
      desktop: variantDesktop,
      largeDesktop: variantLargeDesktop,
      mobile: variant,
      tablet: variantTablet,
      tabletLandscape: variantTabletLandscape,
    })}
`;

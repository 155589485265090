import styled, { css } from "styled-components";
import React from "react";
import classNames from "classnames";
import { MARGIN_WIDTH, GUTTER_WIDTH, VIEWPORT_WIDTH, COLUMNS } from "./theme";
import { responsiveMixin } from "./style-mixins";

export const Container = styled.div`
  position: relative;
  width: 100%;
  padding-right: ${MARGIN_WIDTH.MOBILE}px;
  padding-left: ${MARGIN_WIDTH.MOBILE}px;
  margin-right: auto;
  margin-left: auto;

  ${responsiveMixin({
    mixin: ({
      gutterWidth,
      viewportWidth,
    }: {
      gutterWidth: number;
      viewportWidth: number;
    }) => css`
      padding-right: ${gutterWidth / 2}px;
      padding-left: ${gutterWidth / 2}px;
      max-width: ${viewportWidth}px;
    `,
    tablet: {
      gutterWidth: GUTTER_WIDTH.TABLET,
      viewportWidth: VIEWPORT_WIDTH.TABLET,
    },
    tabletLandscape: {
      gutterWidth: GUTTER_WIDTH.TABLET_LANDSCAPE,
      viewportWidth: VIEWPORT_WIDTH.TABLET_LANDSCAPE,
    },
    desktop: {
      gutterWidth: GUTTER_WIDTH.DESKTOP,
      viewportWidth: VIEWPORT_WIDTH.DESKTOP,
    },
    largeDesktop: {
      gutterWidth: GUTTER_WIDTH.LARGE_DESKTOP,
      viewportWidth: VIEWPORT_WIDTH.LARGE_DESKTOP,
    },
  })}
`;

type RowProps = {
  flexWrap?: string;
};
export const Row = styled.div<RowProps>`
  display: flex;
  flex-wrap: ${({ flexWrap = "wrap" }) => flexWrap};

  ${responsiveMixin({
    mixin: (value: number) => css`
      margin-right: -${value / 2}px;
      margin-left: -${value / 2}px;
    `,
    mobile: GUTTER_WIDTH.MOBILE,
    tablet: GUTTER_WIDTH.TABLET,
    tabletLandscape: GUTTER_WIDTH.TABLET_LANDSCAPE,
    desktop: GUTTER_WIDTH.DESKTOP,
    largeDesktop: GUTTER_WIDTH.LARGE_DESKTOP,
  })}
`;

type ColumnProps = {
  columns?: number;
  columnsTablet?: number;
  columnsTabletLandscape?: number;
  columnsDesktop?: number;
  columnsLargeDesktop?: number;
};
export const _Column = styled.div<ColumnProps>`
  padding-right: ${GUTTER_WIDTH.MOBILE / 2}px;
  padding-left: ${GUTTER_WIDTH.MOBILE / 2}px;
  flex: 1 1 0%;
  max-width: 100%;

  ${({
    columns,
    columnsTablet,
    columnsTabletLandscape,
    columnsDesktop,
    columnsLargeDesktop,
  }) =>
    responsiveMixin({
      mixin: (columns: number) => css`
        flex: 0 0 ${(columns / COLUMNS) * 100}%;
        max-width: ${(columns / COLUMNS) * 100}%;
      `,
      mobile: columns,
      tablet: columnsTablet,
      tabletLandscape: columnsTabletLandscape,
      desktop: columnsDesktop,
      largeDesktop: columnsLargeDesktop,
    })}

  ${responsiveMixin({
    mixin: (gutterWidth: number) => css`
      padding-right: ${gutterWidth / 2}px;
      padding-left: ${gutterWidth / 2}px;
    `,
    tablet: GUTTER_WIDTH.TABLET,
    tabletLandscape: GUTTER_WIDTH.TABLET_LANDSCAPE,
    desktop: GUTTER_WIDTH.DESKTOP,
    largeDesktop: GUTTER_WIDTH.LARGE_DESKTOP,
  })}
`;

export const Column: React.FC<React.ComponentProps<typeof _Column>> = ({
  className,
  ...props
}) => (
  <_Column className={classNames("relative w-full", className)} {...props} />
);

type SpacerProps = {
  height?: number;
  heightTablet?: number;
  heightTabletLandscape?: number;
  heightDesktop?: number;
  heightLargeDesktop?: number;
  heightRetailerTerminal?: number;

  width?: number;
  widthTablet?: number;
  widthTabletLandscape?: number;
  widthDesktop?: number;
  widthLargeDesktop?: number;
  widthRetailerTerminal?: number;

  display?: string;
  displayTablet?: string;
  displayTabletLandscape?: string;
  displayDesktop?: string;
  displayLargeDesktop?: string;
  displayRetailerTerminal?: string;
};
export const Spacer = styled.div<SpacerProps>`
  ${({
    width,
    widthTablet,
    widthTabletLandscape,
    widthDesktop,
    widthLargeDesktop,
    widthRetailerTerminal,
  }) =>
    responsiveMixin({
      mixin: (width: number) =>
        css`
          width: ${width}px;
        `,
      mobile: widthRetailerTerminal ? widthRetailerTerminal : width,
      tablet: widthRetailerTerminal ? widthRetailerTerminal : widthTablet,
      tabletLandscape: widthRetailerTerminal
        ? widthRetailerTerminal
        : widthTabletLandscape,
      desktop: widthRetailerTerminal ? widthRetailerTerminal : widthDesktop,
      largeDesktop: widthRetailerTerminal
        ? widthRetailerTerminal
        : widthLargeDesktop,
    })}

  ${({
    height,
    heightTablet,
    heightTabletLandscape,
    heightDesktop,
    heightLargeDesktop,
    heightRetailerTerminal,
  }) =>
    responsiveMixin({
      mixin: (height: number) =>
        css`
          height: ${height}px;
        `,
      mobile: heightRetailerTerminal ? heightRetailerTerminal : height,
      tablet: heightRetailerTerminal ? heightRetailerTerminal : heightTablet,
      tabletLandscape: heightRetailerTerminal
        ? heightRetailerTerminal
        : heightTabletLandscape,
      desktop: heightRetailerTerminal ? heightRetailerTerminal : heightDesktop,
      largeDesktop: heightRetailerTerminal
        ? heightRetailerTerminal
        : heightLargeDesktop,
    })}

  ${({
    display,
    displayTablet,
    displayTabletLandscape,
    displayDesktop,
    displayLargeDesktop,
    displayRetailerTerminal,
  }) =>
    responsiveMixin({
      mixin: (display: string) => css`
        display: ${display};
      `,
      mobile: displayRetailerTerminal ? displayRetailerTerminal : display,
      tablet: displayRetailerTerminal ? displayRetailerTerminal : displayTablet,
      tabletLandscape: displayRetailerTerminal
        ? displayRetailerTerminal
        : displayTabletLandscape,
      desktop: displayRetailerTerminal
        ? displayRetailerTerminal
        : displayDesktop,
      largeDesktop: displayRetailerTerminal
        ? displayRetailerTerminal
        : displayLargeDesktop,
    })}
`;

import styled, { css } from "styled-components";

const colorMixin = (color: string) => css`
  color: ${color};
`;

type Props = {
  color?: string;
};
const UnstyledButton = styled.button<Props>`
  border: none;
  display: inline;
  background-color: transparent;
  ${({ color }) => color && colorMixin(color)};
  padding: 0;
  margin: 0;
  font-weight: inherit;
  outline: none;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`;

export default UnstyledButton;

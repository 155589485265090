import { createGlobalStyle, css } from "styled-components";
import { COLOR } from "./theme";

const GlobalStyle = createGlobalStyle`${css`
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Lato", sans-serif;
    color: ${COLOR.NEUTRAL_GREY_1};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }
`}`;

export default GlobalStyle;

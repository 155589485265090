import React, { ReactNodeArray } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import BottomBar from "../atoms/bottom-bar";
import { Spacer, Row, Column, Container } from "../atoms/grid";
import Button from "../atoms/button";
import UnstyledButton from "../atoms/unstyled-button";
import { Heading } from "../atoms/typography";
import { showIntercom } from "../../utils/show-intercom";
import useIsFeatureFlagEnabled from "../../hooks/use-is-feature-flag-enabled";
import { openZendesk } from "../../utils/zendesk-view";

enum Texts {
  HEADER = "error.header",
  TITLE = "error.title",
  BODY = "error.body",
  CTA = "error.cta",
}

const BodyColumn = styled(Column)`
  margin: auto;
  max-width: 343px;
`;

const SupportText = styled(UnstyledButton)`
  padding: 0;
  color: ${({ theme }) => theme.colors.primary};
  white-space: nowrap;
  &:focus {
    outline: none;
  }
`;

const ErrorPage: React.FC = () => {
  const isZendeskEnabled = useIsFeatureFlagEnabled("ENABLE_ZENDESK");

  return (
    <div className="relative flex min-h-screen flex-col text-center">
      <div className="flex flex-1 items-center bg-grey-6">
        <Container>
          <Row>
            <Column>
              <h1 className="text-5xl font-bold">
                <FormattedMessage id={Texts.HEADER} />
              </h1>
            </Column>
          </Row>
          <Spacer
            height={56}
            heightTablet={116}
            heightDesktop={80}
            heightLargeDesktop={140}
          />
          <Row>
            <Column>
              <Heading variant={4} variantTablet={3}>
                <FormattedMessage id={Texts.TITLE} />
              </Heading>
            </Column>
          </Row>
          <Spacer height={8} heightTablet={12} />
          <Row>
            <BodyColumn>
              <span className="text-base text-grey-1">
                <FormattedMessage
                  id={Texts.BODY}
                  values={{
                    Support: (chunks: ReactNodeArray) => (
                      <SupportText
                        onClick={isZendeskEnabled ? openZendesk : showIntercom}
                      >
                        {chunks}
                      </SupportText>
                    ),
                  }}
                />
              </span>
            </BodyColumn>
          </Row>
        </Container>
      </div>
      <div className="sticky bottom-0 z-30 bg-grey-6">
        <BottomBar>
          <Row className="justify-center">
            <Column
              css={`
                flex-grow: 0;
              `}
            >
              <Button
                variant="primary"
                block
                onClick={() => document.location.reload()}
              >
                <FormattedMessage id={Texts.CTA} />
              </Button>
            </Column>
          </Row>
        </BottomBar>
      </div>
    </div>
  );
};

export default ErrorPage;

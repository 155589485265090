import { DefaultTheme } from "styled-components";
import { compact } from "lodash";
import expertLogo from "./expert-logo.svg";
import defaultLogo from "./default-logo.svg";
import defaultLottieLoadingAnimation from "./default-loading-animation.json";

export const COLUMNS = 12;
export const SIZE_UNIT = 8;

export const BREAKPOINT = {
  TABLET: 768,
  TABLET_LANDSCAPE: 1024,
  DESKTOP: 1366,
  LARGE_DESKTOP: 1920,
};

export const ORIENTATION = {
  PORTRAIT: "portrait",
  LANDSCAPE: "landscape",
};

export const makeMediaQuery = ({
  min,
  max,
  orientation,
}: {
  min?: keyof typeof BREAKPOINT;
  max?: keyof typeof BREAKPOINT;
  orientation?: keyof typeof ORIENTATION;
}) =>
  `@media ${compact([
    min ? `(min-width: ${BREAKPOINT[min]}px)` : undefined,
    max ? `(max-width: ${BREAKPOINT[max]}px)` : undefined,
    orientation ? `(orientation: ${ORIENTATION[orientation]})` : undefined,
  ]).join(" and ")}`;

export const VIEWPORT_WIDTH = {
  TABLET: 708,
  TABLET_LANDSCAPE: 974,
  DESKTOP: 1096,
  LARGE_DESKTOP: 1480,
};

export const MARGIN_WIDTH = {
  MOBILE: 16,
};

export const GUTTER_WIDTH = {
  MOBILE: 10,
  TABLET: 12,
  TABLET_LANDSCAPE: 10,
  DESKTOP: 20,
  LARGE_DESKTOP: 20,
};

export const NAV_BAR_HEIGHT = {
  MOBILE: 60,
  TABLET: 80,
  TABLET_LANDSCAPE: 80,
  DESKTOP: 80,
  LARGE_DESKTOP: 80,
};

export const COLOR = {
  PRIMARY_PINK: "#FF6464",
  ACCENT_MENTHE: "#44E2C4",
  SECONDARY_YELLOW: "#FFD921",
  SECONDARY_BLUE: "#0069FF",
  TERTIARY_MANDARINE: "#FF9300",
  TERTIARY_CIEL: "#00D5FF",
  TERTIARY_POMME: "#7CFB79",
  TERTIARY_CANCELED: "#FF0000",
  NEUTRAL_GREY_1: "#4A3F3F",
  NEUTRAL_GREY_2: "#ACA1A1",
  NEUTRAL_GREY_3: "#DAD5D5",
  NEUTRAL_GREY_4: "#F1EEEE",
  NEUTRAL_GREY_5: "#F7F4F4",
  NEUTRAL_GREY_6: "#FDFCFC",
  NEUTRAL_WHITE: "#FFFFFF",
};

export const defaultTheme: DefaultTheme = {
  colors: {
    primary: COLOR.PRIMARY_PINK,
  },
  logoUrl: defaultLogo,
  lottieLoadingAnimation: defaultLottieLoadingAnimation,
};

export const expertTheme: DefaultTheme = {
  colors: {
    primary: COLOR.SECONDARY_BLUE,
  },
  logoUrl: expertLogo,
  lottieLoadingAnimation: defaultLottieLoadingAnimation,
};

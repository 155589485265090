import { IntlProvider as _IntlProvider } from "react-intl";
import * as Sentry from "@sentry/react";
import React, { useMemo } from "react";
import { Severity } from "@sentry/react";
import messages from "../locales/fr-FR.json";

type Props = {
  children: React.ReactNode;
  extraMessages?: Record<string, string>;
};
const IntlProvider = ({ children, extraMessages = {} }: Props) => {
  const _messages = useMemo(
    () => ({ ...messages, ...extraMessages }),
    [extraMessages],
  );

  return (
    <_IntlProvider
      onError={(error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        Sentry.captureException(error, { level: Severity.Warning });
      }}
      locale="fr"
      defaultLocale="fr"
      messages={_messages}
      defaultRichTextElements={{ br: () => <br /> }}
    >
      {children}
    </_IntlProvider>
  );
};

export default IntlProvider;

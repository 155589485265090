import React, { memo, useRef, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import classNames from "classnames";
import Lottie from "../../components/atoms/lottie";

const useTimeout = (callback: () => void, delay: number) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay === null) {
      return;
    }

    const id = setTimeout(tick, delay);
    return () => clearTimeout(id);
  }, [delay]);
};

const Loading = memo(({ className }: { className?: string }) => {
  const theme = useTheme();
  const [showLoader, setShowLoader] = useState(false);
  useTimeout(() => {
    setShowLoader(true);
  }, 1000);

  return (
    <div
      className={classNames(
        "absolute inset-0 flex items-center justify-center",
        className,
      )}
    >
      {showLoader && (
        <Lottie
          className="w-full max-w-sm"
          animationData={theme.lottieLoadingAnimation}
          loop
        />
      )}
    </div>
  );
});

export default Loading;

import React from "react";
import styled from "styled-components";
import { Container } from "./grid";

const OuterBottomBar = styled.nav`
  min-height: 60px;
  padding-bottom: env(safe-area-inset-bottom);
`;

const BottomBar: React.FC<{
  children?: React.ReactNode;
  className?: string;
}> = ({ children, className }) => (
  <OuterBottomBar
    className={`relative flex items-center bg-white shadow ${className}`}
  >
    <Container className="py-4">{children}</Container>
  </OuterBottomBar>
);

export default BottomBar;

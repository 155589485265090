import classNames from "classnames";
import React from "react";

const Layout: React.FC<{
  children?: React.ReactNode;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  className?: string;
  layoutClassName?: string;
}> = ({ children, footer, header, className, layoutClassName }) => {
  const hideHeaderAndFooter = window.location.host.includes("saasv2");

  return (
    <div
      className={classNames(
        "relative flex min-h-screen flex-col",
        layoutClassName,
      )}
    >
      {header && !hideHeaderAndFooter && (
        <div className="sticky top-0 z-30">{header}</div>
      )}
      <div className={classNames("flex-1 bg-grey-6", className)}>
        {children}
      </div>
      {footer && !hideHeaderAndFooter && (
        <div className="sticky bottom-0 z-30 bg-grey-6">{footer}</div>
      )}
    </div>
  );
};
export default Layout;

import * as Sentry from "@sentry/react";
import * as Tracing from "@sentry/tracing";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

Sentry.init({
  dsn: "https://715dfad018f242459894733f038eaca7@sentry.io/5178912",
  integrations: [
    new Tracing.Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 1.0,
  release: process.env.RELEASE_ID,
  environment: process.env.ENVIRONMENT,
  enabled: process.env.ENVIRONMENT !== "development",
});

import { QueryParamProvider } from "use-query-params";
import React, { StrictMode, Suspense } from "react";
import { Router } from "react-router";
import { render } from "react-dom";
import { ThemeProvider } from "styled-components";
import { Route } from "react-router-dom";
import ErrorPage from "./deprecated/organisms/error-page";
import StripeProvider from "./services/stripe-provider";

import apolloClient from "./services/graphql/client";
import { COLOR, defaultTheme } from "./deprecated/atoms/theme";
import Reboot from "./deprecated/atoms/reboot";
import GlobalStyle from "./deprecated/atoms/global-style";
import Loading from "./deprecated/organisms/loading";
import IntlProvider from "./services/intl-provider";

import "./main.css";

if (!process.env.STRIPE_PUBLISHABLE_KEY) {
  throw new Error(`STRIPE_PUBLISHABLE_KEY is required`);
}

Intercom("boot", {
  hide_default_launcher: true,
  action_color: COLOR.ACCENT_MENTHE,
  background_color: COLOR.PRIMARY_PINK,
});

const App = React.lazy(
  () =>
    import(
      "./app"
      /* webpackPreload: true */
      /* webpackChunkName: "app" */
    ),
);

// Delete when there is no need of legacy urls
if (window.location.host === "jardiland.dealt.fr") {
  window.location.href = "https://services.jardiland.com";
}

if (window.location.host.startsWith("short.dealt.fr")) {
  window.location.href =
    "https://drive.google.com/file/d/1OjniPShamWiUczT42bSQVWFaaKhbSkaf/view";
}

render(
  <StrictMode>
    <IntlProvider>
      <Reboot />
      <GlobalStyle />
      <ThemeProvider theme={defaultTheme}>
        <Sentry.ErrorBoundary fallback={<ErrorPage />}>
          <StripeProvider
            stripePublishableKey={process.env.STRIPE_PUBLISHABLE_KEY}
          >
            <Router history={history}>
              <QueryParamProvider ReactRouterRoute={Route}>
                <Suspense fallback={<Loading />}>
                  <App apolloClient={apolloClient} />
                </Suspense>
              </QueryParamProvider>
            </Router>
          </StripeProvider>
        </Sentry.ErrorBoundary>
      </ThemeProvider>
    </IntlProvider>
  </StrictMode>,
  document.getElementById("app"),
);

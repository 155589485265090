import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import Layout from "../atoms/layout";
import BottomBar from "../atoms/bottom-bar";
import { Row, Column, Container } from "../atoms/grid";
import Button from "../atoms/button";
import { COLOR } from "../atoms/theme";

enum Texts {
  HEADER = "404.header",
  TITLE = "404.title",
  BODY = "404.body",
  CTA = "404.cta",
}

const Title = styled(Column)`
  text-align: center;
  color: ${COLOR.NEUTRAL_GREY_1};
`;

const Body = styled(Column)`
  text-align: center;
  color: ${COLOR.NEUTRAL_GREY_1};
`;

const NotFound = () => {
  const history = useHistory();

  const handleButtonClick = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Layout
      footer={
        <BottomBar>
          <Row className="justify-center">
            <Column>
              <Button
                variant="primary"
                onClick={handleButtonClick}
                className="block w-full"
              >
                <FormattedMessage id={Texts.CTA} />
              </Button>
            </Column>
          </Row>
        </BottomBar>
      }
    >
      <Container>
        <Row className="mt-36 tablet:mt-72 desktop:mt-44">
          <Column>
            <h1 className="text-center text-7xl font-bold text-grey-4 tablet:text-8xl">
              <FormattedMessage id={Texts.HEADER} />
            </h1>
          </Column>
        </Row>
        <Row className="mt-6 justify-center tablet:mt-20">
          <Title>
            <h2 className="text-2xl font-bold tablet:text-3xl">
              <FormattedMessage id={Texts.TITLE} />
            </h2>
          </Title>
        </Row>
        <Row className="mt-2 justify-center tablet:mt-4">
          <Body>
            <FormattedMessage id={Texts.BODY} />
          </Body>
        </Row>
      </Container>
    </Layout>
  );
};

export default NotFound;

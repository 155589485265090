import { useState, useEffect } from "react";
import ky from "ky";

type Loading = {
  state: "LOADING";
};

type Loaded = {
  state: "LOADED";
  data: unknown;
};

type Error = {
  state: "ERROR";
};

type State = Loading | Loaded | Error;

const useFetchJson = (url?: string) => {
  const [state, setState] = useState<State>({ state: "LOADING" });
  useEffect(() => {
    if (url) {
      ky.get(url, { retry: 10 })
      .json()
      .then((data) => setState({ state: "LOADED", data }))
      .catch(() => setState({ state: "ERROR" }));
    } else {
      setState({ state: "ERROR" })
    }
  }, [url]);

  return state;
};

export default useFetchJson;
